import { Card, SkeletonBodyText, List } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PlanEnum, RequestStateEnum } from '../common';

export default function ThemeGuideCard(props) {
    const [isProfessional, setIsProfessional] = useState(null);

    const activePlan = useSelector((state) => {
        return state.plans.activePlan;
    });

    const shopUrl = useSelector((state) => {
        return state.shop.url;
    });

    useEffect(() => {
        if (activePlan.state === RequestStateEnum.SUCCESS) {
            setIsProfessional(
                activePlan?.data?.plan?.title === PlanEnum.PROFESSIONAL
            );
        }
    }, [activePlan]);

    const RenderInfoTexts = () => {
        if (isProfessional === null) {
            return (
                <div style={{ marginTop: '22px' }}>
                    <SkeletonBodyText lines={2} />
                </div>
            );
        } else {
            return (
                <p style={{ marginTop: '10px' }}>
                    {isProfessional ? (
                        <List>
                            <List.Item>
                                In case of any problems regarding the
                                functionality, contact support.
                            </List.Item>
                        </List>
                    ) : (
                        <List>
                            <List.Item>
                                You must have a professional plan in order to
                                activate this feature.
                            </List.Item>
                        </List>
                    )}
                </p>
            );
        }
    };

    const uuid = process.env.REACT_APP_THEME_APP_UUID;
    const appEmbedHandle = 'find-similar-products-embed';
    const themeAppUrl = `https://${shopUrl}/admin/themes/current/editor?context=apps&activateAppId=${uuid}/${appEmbedHandle}`;

    return (
        <Card
            title='Similar Products Button'
            primaryFooterAction={{
                content: 'Customize',
                onAction: () => {
                    window.top.location.href = themeAppUrl;
                },
                disabled: !isProfessional,
            }}>
            <Card.Section>
                Add a camera button to every product card to let users shop for
                similar products.
                {RenderInfoTexts()}
            </Card.Section>
        </Card>
    );
}
