import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Card, SkeletonBodyText, Banner } from '@shopify/polaris';
import { PlanEnum, RequestStateEnum } from '../common';
import { mainApi } from '../mainApi';

export default function PlanStatus() {
    const activePlan = useSelector((state) => state.plans.activePlan);
    const plans = useSelector((state) => state.plans.plans);

    const navigate = useNavigate();
    const [productsCount, setProductsCount] = useState(0);

    const planStatusMessage = () => {
        if (
            activePlan.state === RequestStateEnum.INIT ||
            activePlan.state === RequestStateEnum.LOADING
        ) {
            return <SkeletonBodyText lines={2} />;
        } else if (activePlan.state === RequestStateEnum.ERROR) {
            return 'An error occurred';
        } else {
            if (Object.keys(activePlan.data).length === 0) {
                return 'No plans selected';
            } else {
                return (
                    <span>
                        You are currently on plan:{' '}
                        <Badge
                            progress='complete'
                            status={
                                activePlan.data.plan.title ===
                                PlanEnum.PROFESSIONAL
                                    ? 'success'
                                    : ''
                            }>
                            {activePlan.data.plan.title}
                        </Badge>
                    </span>
                );
            }
        }
    };

    const getProductsCount = async () => {
        try {
            const { data } = await mainApi.get('/sync/');
            setProductsCount(data.result.total);
        } catch (error) {}
    };

    const planUpgradeMessage = () => {
        let planSuggestion = '';
        const {
            product_limit: productLimit,
            has_product_limit: hasProductLimit,
        } = activePlan.data.plan;

        if (productsCount > productLimit && hasProductLimit) {
            // get all plan limits
            const planLimits = plans.data.map((plan) => plan.product_limit);
            // set witch plan has the appropriate limit considering the productsCount
            planLimits.every((limit, index) => {
                if (productsCount < (limit !== 0 ? limit : +Infinity)) {
                    planSuggestion = plans.data[index].title;
                    return false;
                }
                return true;
            });
            return (
                <div style={{ marginBottom: '16px' }}>
                    <Banner
                        title="Your catalog has exceeded your plan's products limit"
                        action={{
                            content: 'Upgrade Plan',
                            onAction: () => navigate('/plans'),
                        }}
                        status='warning'>
                        <div style={{ marginTop: '10px' }}>
                            You have {productsCount} products in your catalog.
                            Some of your products are not included in your
                            search index. We recommend you to upgrade to{' '}
                            {<Badge status='warning'>{planSuggestion}</Badge>}{' '}
                            plan to include all of your products in your search
                            index.
                        </div>
                    </Banner>
                </div>
            );
        }
    };

    useEffect(() => {
        getProductsCount();
    }, []);

    return (
        <>
            {plans.state === RequestStateEnum.SUCCESS &&
            activePlan.state === RequestStateEnum.SUCCESS
                ? planUpgradeMessage()
                : ''}
            <Card
                title='Current Plan'
                primaryFooterAction={{
                    content: 'Change Plan',
                    onAction: () => {
                        navigate('/plans');
                    },
                    loading: activePlan.state === RequestStateEnum.LOADING,
                    disabled: activePlan.state !== RequestStateEnum.SUCCESS,
                }}>
                <Card.Section>{planStatusMessage()}</Card.Section>
            </Card>
        </>
    );
}
