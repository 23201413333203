import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider, Frame } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';

ReactDOM.render(
    <React.StrictMode>
        <AppProvider i18n={enTranslations}>
            <Provider store={store}>
                <Frame>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Frame>
            </Provider>
        </AppProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
