import React, { useCallback } from 'react';
import { Modal } from '@shopify/polaris';
import styles from '../styles/themeGuideModal.module.css';

export default function ThemeGuideModal(props) {
    const { showModal, setShowModal } = props;
    const toggleActive = useCallback(() => setShowModal(false), [setShowModal]);

    return (
        <Modal
            large
            open={showModal}
            onClose={toggleActive}
            title='Theme setting guide'
            primaryAction={{
                content: 'Close',
                onAction: toggleActive,
            }}
            limitHeight={false}>
            <Modal.Section>
                <div className={styles.text}>
                    <p style={{ marginBottom: '1rem' }}>
                        The app builds on Shopify's Theme App Extension
                        Technology to allow you to customize the interface
                        directly in your theme's settings. You can automatically
                        navigate to The Visual Search Widget by clicking the
                        "Customize Interface" button on the app's main page.
                    </p>
                    <img
                        style={{
                            maxWidth: '100%',
                        }}
                        src='images/themeSettingGuide.png'
                        alt='guide'
                    />
                    <p>
                        You can also go to the "App embeds" section of your
                        "Theme settings" whenever customizing your theme to edit
                        the visual settings of the widget.
                    </p>
                    <p style={{ marginTop: '2rem' }}>
                        You can customize the widget's settings in the following
                        ways:
                    </p>
                    <p>
                        <strong> 1- Position of visual search icon:</strong> You
                        can show the camera button on your storefront by either
                        placing it as a floating button (on the left or right
                        side of the window) or by placing it in your theme's
                        search bar.
                    </p>
                    <p>
                        <strong>2- Colors:</strong> You can fully customize the
                        colors to mold them into your theme.
                    </p>
                    <p>
                        <strong>3- Sizes:</strong> You can customize the sizing
                        of the visual search button and the search box.
                    </p>
                    <p>
                        <strong>4- Texts:</strong> You can change every text
                        shown in the search box so you can modify the language
                        used in the UI by your needs.
                    </p>
                </div>
            </Modal.Section>
        </Modal>
    );
}
