import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RequestStateEnum } from '../common';
import { mainApi } from '../mainApi';

const initialState = {
    plans: {
        state: RequestStateEnum.INIT,
        data: null,
    },
    activePlan: {
        state: RequestStateEnum.INIT,
        data: null,
    },
    inPlanSelectProcess: false,
};

export const fetchPlans = createAsyncThunk(
    'plans/fetchPlans',
    async (args, { rejectWithValue }) => {
        try {
            return await mainApi.get('plans/');
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const fetchActivePlan = createAsyncThunk(
    'plans/fetchActivePlan',
    async (args, { rejectWithValue }) => {
        try {
            return await mainApi.get('/me/plans/current/');
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        setInPlanSelectProcess(state, action) {
            state.inPlanSelectProcess = action.payload.inPlanSelectProcess;
        },
    },
    extraReducers: {
        [fetchPlans.pending]: (state) => {
            state.plans.state = RequestStateEnum.LOADING;
        },
        [fetchPlans.fulfilled]: (state, action) => {
            state.plans.state = RequestStateEnum.SUCCESS;
            state.plans.data = action.payload.data;
        },
        [fetchPlans.rejected]: (state, action) => {
            state.plans.state = RequestStateEnum.ERROR;
            state.plans.data = action.payload;
        },
        [fetchActivePlan.pending]: (state) => {
            state.activePlan.state = RequestStateEnum.LOADING;
        },
        [fetchActivePlan.fulfilled]: (state, action) => {
            state.activePlan.state = RequestStateEnum.SUCCESS;
            state.activePlan.data = action.payload.data;
        },
        [fetchActivePlan.rejected]: (state, action) => {
            if (action.payload.response.status === 404) {
                state.activePlan.state = RequestStateEnum.SUCCESS;
                state.activePlan.data = {};
            } else {
                state.activePlan.state = RequestStateEnum.ERROR;
                state.activePlan.data = action.payload.data;
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { setInPlanSelectProcess } = plansSlice.actions;

export default plansSlice.reducer;
