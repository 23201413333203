import React, { useState, useCallback, useRef, useEffect } from 'react';
import { RequestStateEnum } from '../common';
import { mainApi } from '../mainApi';
import SyncStatusCard from './syncStatusCard';
import {
    Page,
    Layout,
    Card,
    List,
    Spinner,
    Subheading,
    Stack,
    Icon,
} from '@shopify/polaris';
import { CircleTickOutlineMinor } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';

export default function FirstSyncPage() {
    const [hasSearchIndex, setHasSearchIndex] = useState({
        data: undefined,
        state: RequestStateEnum.INIT,
    });

    const navigate = useNavigate();

    const timerId = useRef(0);

    const [syncSuccess, setSyncSuccess] = useState(false);

    const fetchHasSearchIndex = useCallback(async () => {
        try {
            setHasSearchIndex({
                ...hasSearchIndex,
                state: RequestStateEnum.LOADING,
            });
            const res = await mainApi.get('/has-search-index/');
            setHasSearchIndex({
                state: RequestStateEnum.SUCCESS,
                data: res.data.has_search_index,
            });
            if (res.data.has_search_index === true) {
                clearInterval(timerId.current);
                setTimeout(() => {
                    navigate('/');
                }, 5000);
            }
        } catch {
            setHasSearchIndex({
                ...hasSearchIndex,
                state: RequestStateEnum.ERROR,
            });
        }
    }, [setHasSearchIndex, navigate, hasSearchIndex]);

    const onSyncSuccess = useCallback(async () => {
        setSyncSuccess(true);
        await fetchHasSearchIndex();
        if (hasSearchIndex.data !== true && timerId.current === 0) {
            timerId.current = setInterval(fetchHasSearchIndex, 1000);
        }
    }, [fetchHasSearchIndex, hasSearchIndex]);

    useEffect(() => {
        return () => {
            clearInterval(timerId.current);
        };
    }, []);

    const renderSearchIndex = () => {
        return (
            <Card title='Search Index'>
                <Card.Section
                    subdued
                    title={
                        <Subheading>Your product's data is synced!</Subheading>
                    }>
                    <List>
                        <List.Item>
                            Now your search index is getting created
                        </List.Item>
                    </List>
                </Card.Section>
                <Card.Section
                    subdued={hasSearchIndex.data}
                    title={
                        <Stack>
                            {!hasSearchIndex.data ? (
                                <Spinner size='small' />
                            ) : (
                                <Icon
                                    source={CircleTickOutlineMinor}
                                    color='success'
                                />
                            )}
                            <Subheading>
                                {!hasSearchIndex.data
                                    ? 'Creating search index'
                                    : 'Search index created!'}
                            </Subheading>
                        </Stack>
                    }></Card.Section>
                {hasSearchIndex.data ? (
                    <Card.Section
                        title={
                            <Stack>
                                <Spinner size='small' />
                                <Subheading>
                                    Redirecting to the app's settings page...
                                </Subheading>
                            </Stack>
                        }></Card.Section>
                ) : (
                    ''
                )}
            </Card>
        );
    };

    return (
        <Page
            title='Analyze Products'
            subtitle="In order to create your search index, we have to analyze your products' images">
            <Layout>
                <Layout.Section>
                    {!syncSuccess ? (
                        <SyncStatusCard onSuccess={onSyncSuccess} />
                    ) : (
                        renderSearchIndex()
                    )}
                </Layout.Section>
            </Layout>
        </Page>
    );
}
