import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { mainApi } from '../mainApi';

const initialState = {
    url: undefined,
    trialDaysGift: 0,
};

export const fetchHasSearchIndex = createAsyncThunk(
    'shop/fetchHasSearchIndex',
    async (args, { rejectWithValue }) => {
        try {
            return await mainApi.get('has-search-index/');
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setShopUrl(state, action) {
            state.url = action.payload.url;
        },
        setTrialDaysGift(state, action) {
            state.trialDaysGift = action.payload.trialDaysGift;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setShopUrl, setTrialDaysGift } = shopSlice.actions;

export default shopSlice.reducer;
