import axios from 'axios';

export const mainApi = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
});

export const setAccessToken = (token) => {
    mainApi.defaults.headers.common['x-shopify-access-token'] = token;
};

export const setShopUrl = (shopUrl) => {
    mainApi.defaults.headers.common['x-shopify-shop-url'] = shopUrl;
};
