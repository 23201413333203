import { WelcomePage, OldShopWelcomePage } from './welcome';
import { PlansPage, PlanConfirmPage } from './plans';
import { FirstSyncPage } from './sync';
import { MainPage } from './main';
// const authQueries = `?shop=${process.env.REACT_APP_SHOP}&access_token=${process.env.REACT_APP_AT}`;

const routes = [
    {
        path: '/',
        component: <MainPage />,
    },
    {
        path: '/old-shop-welcome',
        component: <OldShopWelcomePage />,
    },
    {
        path: '/welcome',
        component: <WelcomePage />,
    },
    {
        path: '/plans',
        component: <PlansPage />,
    },
    {
        path: '/plan-confirm',
        component: <PlanConfirmPage />,
    },
    {
        path: '/first-sync',
        component: <FirstSyncPage />,
    },
];

export default routes;
