import { Card } from '@shopify/polaris';
import { useSelector } from 'react-redux';

export default function ThemeGuideCard(props) {
    // const themeAppUrl = 'https://alaki.com';
    const shopUrl = useSelector((state) => {
        return state.shop.url;
    });
    const uuid = process.env.REACT_APP_THEME_APP_UUID;
    const appEmbedHandle = 'app-searchembed';
    const themeAppUrl = `https://${shopUrl}/admin/themes/current/editor?context=apps&activateAppId=${uuid}/${appEmbedHandle}`;

    return (
        <Card
            title='Visual Search Widget'
            primaryFooterAction={{
                content: 'Customize Interface',
                onAction: () => {
                    window.top.location.href = themeAppUrl;
                },
            }}
            secondaryFooterActions={[
                {
                    content: 'Learn',
                    onAction: () => {
                        props.setShowModal(true);
                    },
                },
            ]}>
            <div style={{ marginBottom: '30px' }}>
                <Card.Section>
                    For customizing the Smart Visual Search interface in your
                    storefront, click on the button below to customize the
                    widget directly in your theme settings.
                </Card.Section>
            </div>
        </Card>
    );
}
