import React, { useEffect, useCallback, useLayoutEffect } from 'react';
import { Routes as Router, Route, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { fetchPlans, fetchActivePlan } from './plans';
import { setShopUrl as setShopUrlStore, setTrialDaysGift } from './common';
import { setAccessToken, setShopUrl } from './mainApi';
import { useDispatch } from 'react-redux';
import routes from './routes';
import './App.css';

function App() {
    const dispatch = useDispatch();

    const setShopParams = useCallback(() => {
        const params = new URLSearchParams(window.location.search);
        const isDev = process.env.NODE_ENV === 'development';
        // set default shop url and access token in development
        const accessToken = isDev
            ? process.env.REACT_APP_AT
            : params.get('access_token');

        const shopUrl = isDev ? process.env.REACT_APP_SHOP : params.get('shop');
        if (accessToken !== null) {
            setAccessToken(accessToken);
        }
        if (shopUrl !== null) {
            setShopUrl(shopUrl);
            dispatch(setShopUrlStore({ url: shopUrl }));
        }
        const trialDaysGift = params.get('trial_days_gift');
        if (trialDaysGift !== null) {
            dispatch(
                setTrialDaysGift({ trialDaysGift: Number(trialDaysGift) })
            );
        }
    }, [dispatch]);
    useLayoutEffect(() => {
        setShopParams();
    }, [setShopParams]);

    const fetchPlansOnPageInit = useCallback(() => {
        dispatch(fetchPlans());
        dispatch(fetchActivePlan());
    }, [dispatch]);
    useEffect(() => {
        fetchPlansOnPageInit();
    }, [fetchPlansOnPageInit]);

    const location = useLocation();

    // load tidio

    useEffect(() => {
        const tidioScript = document.createElement('script');
        tidioScript.async = true;
        tidioScript.src =
            'https://code.tidio.co/fwrxtwrxgspzhevk7zvbsttzpdi9ll3g.js';
        document.body.appendChild(tidioScript);
    }, []);

    return (
        <div>
            <SwitchTransition mode='out-in'>
                <CSSTransition
                    key={location.key}
                    classNames='fade'
                    timeout={100}>
                    <Router location={location}>
                        {routes.map((route, i) => (
                            <Route
                                exact
                                key={i}
                                path={route.path}
                                element={route.component}
                            />
                        ))}
                    </Router>
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
}

export default App;
