import React from 'react';
import { EmptyState } from '@shopify/polaris';
import { useNavigate } from 'react-router';

export default function WelcomePage() {
    const navigate = useNavigate();
    return (
        <div
            style={{
                margin: 'auto',
                maxWidth: '700px',
                width: '90%',
                marginTop: '20px',
                overflow: 'hidden',
                padding: '20px',
            }}>
            <EmptyState
                fullWidth={true}
                heading='Empower your shop with Smart Visual Search!'
                action={{
                    content: 'Start now',
                    onAction: () => {
                        navigate('/plans');
                    },
                }}
                image='images/hello.svg'
                largeImage='images/hello.svg'>
                <div
                    style={{
                        width: '100%',
                        height: '1px',
                        background: '#ccc',
                    }}></div>
                <ul style={{ textAlign: 'left' }}>
                    <li style={{ marginTop: '25px' }}>
                        Let your customers have a better shopping experience!
                        They can find products with{' '}
                        <strong style={{ color: '#008060' }}>
                            just a photo
                        </strong>
                        .
                        <br />
                        This is the new way of buying goods online!
                    </li>
                </ul>
            </EmptyState>
        </div>
    );
}
