import React, { useCallback, useEffect, useState } from 'react';
import {
    SettingToggle,
    Heading,
    TextContainer,
    TextStyle,
    Toast,
} from '@shopify/polaris';
import { mainApi } from '../mainApi';
import { RequestStateEnum } from '../common';

export default function AutoSyncOption() {
    const [enabled, setEnabled] = useState({
        state: RequestStateEnum.INIT,
        data: undefined,
    });

    const [toast, setToast] = useState({
        active: false,
        error: false,
        message: '',
    });

    const getAutoSync = useCallback(async () => {
        setEnabled({ state: RequestStateEnum.LOADING });
        try {
            const res = await mainApi.get('/auto-sync');
            setEnabled({
                data: res.data.enabled,
                state: RequestStateEnum.SUCCESS,
            });
        } catch {
            setEnabled({ state: RequestStateEnum.ERROR });
        }
    }, []);

    useEffect(() => {
        getAutoSync();
    }, [getAutoSync]);

    const handleAction = useCallback(async () => {
        if (enabled.state === RequestStateEnum.ERROR) {
            getAutoSync();
        } else {
            setEnabled({ state: RequestStateEnum.LOADING });
            try {
                const res = await mainApi.put('/auto-sync/', {
                    enabled: !enabled.data,
                });
                if (res.data.success) {
                    setEnabled({
                        data: !enabled.data,
                        state: RequestStateEnum.SUCCESS,
                    });
                    setToast({
                        active: true,
                        message: `Autosync is ${enabled.data ? 'off' : 'on'}`,
                        error: false,
                    });
                } else {
                    setEnabled({
                        state: RequestStateEnum.ERROR,
                    });
                    setToast({
                        active: true,
                        message: 'Server error',
                        error: true,
                    });
                }
            } catch {
                setToast({
                    active: true,
                    message: 'Server error',
                    error: true,
                });
                setEnabled({
                    state: RequestStateEnum.ERROR,
                });
            }
        }
    }, [getAutoSync, enabled]);

    const renderMessage = () => {
        if (enabled.state === RequestStateEnum.INIT) {
            return <p>Checking..</p>;
        } else if (enabled.state === RequestStateEnum.ERROR) {
            return <p>Unfortunately an error occured.</p>;
        } else if (enabled.data === true) {
            return (
                <p>
                    Your products are synced{' '}
                    <TextStyle variation='strong'>automatically.</TextStyle>
                </p>
            );
        } else {
            return (
                <p>
                    Your products are synced{' '}
                    <TextStyle variation='strong'>
                        on demand (non-automatic).
                    </TextStyle>
                </p>
            );
        }
    };

    return (
        <>
            <SettingToggle
                action={{
                    content: enabled.data ? 'Disable' : 'Enable',
                    onAction: handleAction,
                    loading: enabled.state === RequestStateEnum.LOADING,
                }}
                enabled={enabled.data}>
                <div
                    style={{
                        marginTop: enabled.data === true ? '10px' : '-10px',
                    }}>
                    <TextContainer>
                        <Heading>AutoSync</Heading>
                        {renderMessage()}
                    </TextContainer>
                </div>
            </SettingToggle>
            {toast.active ? (
                <Toast
                    content={toast.message}
                    error={toast.error}
                    duration={4500}
                    onDismiss={() => {
                        setToast({
                            active: false,
                            error: false,
                        });
                    }}
                />
            ) : null}
        </>
    );
}
