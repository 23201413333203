import { configureStore } from '@reduxjs/toolkit';
import { plansReducer } from './plans';
import { shopReducer } from './common';

export const store = configureStore({
    reducer: {
        plans: plansReducer,
        shop: shopReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false,
        });
    },
});
