export const RequestStateEnum = Object.freeze({
    INIT: 'INIT',
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
});

export const PlanEnum = Object.freeze({
    BASIC: 'Basic',
    STANDARD: 'Standard',
    PROFESSIONAL: 'Professional',
});
