import React, { useState } from 'react';
import {
    Page,
    Layout,
    Card,
    DisplayText,
    Spinner,
    Button,
    Toast,
} from '@shopify/polaris';
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import { useSelector, useDispatch } from 'react-redux';
import { RequestStateEnum } from '../common';
import { mainApi } from '../mainApi';
import PlanCard from './planCard';
import { fetchPlans, setInPlanSelectProcess } from './plansSlice';
import { useNavigate } from 'react-router-dom';

export default function PlansPage() {
    const [toast, setToast] = useState({
        active: false,
        error: false,
        message: '',
    });

    const { data, state } = useSelector((state) => {
        return { data: state.plans.plans.data, state: state.plans.plans.state };
    });

    const hasActivePlan = useSelector((state) => {
        return (
            state.plans.activePlan.state === RequestStateEnum.SUCCESS &&
            Object.keys(state.plans.activePlan.data).length > 0
        );
    });

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const activatePlan = async (planId) => {
        dispatch(setInPlanSelectProcess({ inPlanSelectProcess: true }));
        try {
            const { data } = await mainApi.post('/me/plans/', {
                plan: planId,
            });
            // redirect to confirmation page
            window.top.location.href = data.confirmation_url;
        } catch (err) {
            setToast({
                active: true,
                error: true,
                message: 'Error! Please try again',
            });
        }
        dispatch(setInPlanSelectProcess({ inPlanSelectProcess: false }));
    };

    return (
        <Page fullWidth>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <div style={{ width: '100%', maxWidth: '1500px' }}>
                    {hasActivePlan ? (
                        <Button
                            icon={ArrowLeftMinor}
                            onClick={() => {
                                navigate('/');
                            }}>
                            Main Page
                        </Button>
                    ) : null}
                    <div
                        width='100%'
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginTop: '5vh',
                        }}>
                        <img
                            src='images/plans.svg'
                            alt='plan illustration'
                            style={{ maxWidth: '50%', maxHeight: '30vh' }}
                        />
                    </div>
                    {state === RequestStateEnum.SUCCESS ? (
                        <div style={{ marginBottom: '6rem' }}>
                            <Layout>
                                <Layout.AnnotatedSection
                                    id='storeDetails'
                                    title='Subscription Plans'
                                    description='Please activate one of the following plans to continue:'></Layout.AnnotatedSection>
                                {data.map((plan, index) => (
                                    <Layout.Section key={index} oneThird>
                                        <PlanCard
                                            plan={plan}
                                            onActivatePress={() => {
                                                activatePlan(plan.id);
                                            }}
                                        />
                                    </Layout.Section>
                                ))}
                            </Layout>
                        </div>
                    ) : (
                        <Layout>
                            <div
                                style={{
                                    paddingTop: '10vh',
                                }}>
                                <Card>
                                    <div
                                        style={{
                                            minHeight: '20vh',
                                            minWidth: '50vw',
                                            padding: '20px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                        }}>
                                        {state === RequestStateEnum.LOADING ? (
                                            <>
                                                <DisplayText
                                                    size='medium'
                                                    style={{
                                                        marginBottom: '20px',
                                                    }}>
                                                    Loading Plan Details
                                                </DisplayText>
                                                <div
                                                    style={{
                                                        marginTop: '30px',
                                                    }}>
                                                    <Spinner size='large' />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <DisplayText
                                                    size='medium'
                                                    style={{
                                                        marginBottom: '20px',
                                                    }}>
                                                    Error Loading Plans. Please
                                                    Try Again.
                                                </DisplayText>
                                                <div
                                                    style={{
                                                        marginTop: '30px',
                                                    }}>
                                                    <Button
                                                        primary
                                                        onClick={() => {
                                                            dispatch(
                                                                fetchPlans()
                                                            );
                                                        }}>
                                                        Try Again
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Card>
                            </div>
                        </Layout>
                    )}
                </div>
            </div>
            {toast.active ? (
                <Toast
                    content={toast.message}
                    error={toast.error}
                    duration={4500}
                    onDismiss={() => {
                        setToast({ active: false });
                    }}
                />
            ) : null}
        </Page>
    );
}
