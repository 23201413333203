import React from 'react';
import { useSelector } from 'react-redux';
import { Card, DisplayText, Icon } from '@shopify/polaris';
import { RequestStateEnum } from '../common';
import { MobileAcceptMajor } from '@shopify/polaris-icons';
import { PlanEnum } from '../common';

export default function PlanCard(props) {
    const { activePlan, inPlanSelectProcess } = useSelector((state) => {
        return {
            activePlan: state.plans.activePlan,
            inPlanSelectProcess: state.plans.inPlanSelectProcess,
        };
    });
    const shopTrialDaysGift = useSelector((state) => {
        return state.shop.trialDaysGift;
    });
    const isSelected =
        activePlan.state === RequestStateEnum.SUCCESS &&
        activePlan.data.plan &&
        activePlan.data.plan.id === props.plan.id;

    const FeatureRow = (props) => (
        <div
            style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                marginBottom: '30px',
            }}>
            <div
                style={{
                    width: '25px',
                    marginRight: '20px',
                    paddingBottom: '3px',
                }}>
                <Icon source={MobileAcceptMajor} color='highlight' />
            </div>
            <div style={{ flexGrow: 1, fontSize: '1.2em' }}>{props.text}</div>
        </div>
    );

    const brandingFeature = () => {
        const text = props.plan.hide_branding
            ? 'Hide "Powered by VisionBrain.ai" branding'
            : null;
        return text && <FeatureRow text={text} />;
    };

    const trialDaysFeature = () => {
        const text = props.plan.trial_days
            ? `${Math.max(
                  props.plan.trial_days,
                  shopTrialDaysGift
              )}-day free trial`
            : null;
        return text && <FeatureRow text={text} />;
    };

    const productLimitFeature = () => {
        let text;
        if (props.plan.has_product_limit) {
            text = `Up to ${props.plan.product_limit} product indexing`;
        } else {
            text = `Unlimited product indexing`;
        }
        return <FeatureRow text={text} />;
    };

    const similarProductsRecommendationFeature = () => {
        let text;
        if (props.plan.title === PlanEnum.PROFESSIONAL) {
            text = `Similar Products Recommendation`;
            return <FeatureRow text={text} />;
        }
    };

    return (
        <div style={{ marginBottom: '5rem' }}>
            <Card
                primaryFooterAction={{
                    content: isSelected ? 'SELECTED PLAN' : 'ACTIVATE PLAN',
                    onAction: props.onActivatePress,
                    disabled: isSelected,
                    loading: inPlanSelectProcess,
                }}>
                <Card.Section>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: '20px',
                            minHeight: '500px',
                        }}>
                        <DisplayText
                            size='medium'
                            style={{ marginBottom: '20px' }}>
                            {props.plan.title}
                        </DisplayText>
                        <div
                            style={{
                                marginTop: '50px',
                                fontSize: '2.5em',
                                fontWeight: 600,
                            }}>
                            ${props.plan.monthly_price}/mo
                        </div>
                        <div
                            style={{
                                paddingTop: '50px',
                                paddingLeft: '10%',
                                width: '100%',
                            }}>
                            {trialDaysFeature()}
                            <FeatureRow text='Search Analytics' />
                            <FeatureRow text='Fully customizable UI' />
                            <FeatureRow text='Products automatically indexing' />
                            {brandingFeature()}
                            {productLimitFeature()}
                            {similarProductsRecommendationFeature()}
                        </div>
                    </div>
                </Card.Section>
            </Card>
        </div>
    );
}
