import React, { useState } from 'react';
import { Page, Layout } from '@shopify/polaris';
import ChartCard from './chartCard';
import ThemeGuideCard from './themeGuideCard';
import AutoSyncOption from './autoSyncOption';
import { SyncStatusCard } from '../sync';
import { PlanStatus } from '../plans';
import ThemeGuideModal from './themeGuideModal';
import SimilarProductsGuideCard from './similarProductsGuideCard';

export default function MainPage() {
    const [showModal, setShowModal] = useState(false);

    return (
        <Page title='Smart Visual Search'>
            <Layout>
                <Layout.Section oneHalf>
                    <ThemeGuideCard setShowModal={setShowModal} />
                </Layout.Section>
                <Layout.Section oneHalf>
                    <SimilarProductsGuideCard />
                </Layout.Section>
                <Layout.Section>
                    <PlanStatus />
                </Layout.Section>
                <Layout.Section secondary>
                    <AutoSyncOption />
                </Layout.Section>
                <Layout.Section>
                    <SyncStatusCard />
                </Layout.Section>
                <Layout.Section>
                    <ChartCard />
                </Layout.Section>
            </Layout>
            <ThemeGuideModal
                showModal={showModal}
                setShowModal={setShowModal}
            />
        </Page>
    );
}
