import { Button, Card, DisplayText, Layout, Spinner } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { RequestStateEnum } from '../common';
import { fetchActivePlan } from './plansSlice';
import { useDispatch } from 'react-redux';
import { mainApi } from '../mainApi';

export default function PlanConfirmPage() {
    const [state, setState] = useState(RequestStateEnum.INIT);
    const [newShop, setNewShop] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const confirmPlan = useCallback(
        async (chargeId) => {
            try {
                setState(RequestStateEnum.LOADING);
                const { data } = await mainApi.post('/me/plans/confirm/', {
                    charge_id: chargeId,
                });
                if (data.success === true) {
                    setState(RequestStateEnum.SUCCESS);
                    setTimeout(() => {
                        navigate(newShop ? '/first-sync' : '/main');
                    }, 1500);
                } else {
                    setState(RequestStateEnum.ERROR);
                }
            } catch (err) {
                setState(RequestStateEnum.ERROR);
            }
            dispatch(fetchActivePlan());
        },
        [navigate, newShop, dispatch]
    );

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const chargeId = urlParams.get('charge_id');
        setNewShop(urlParams.get('new') === 'True');
        if (chargeId) {
            confirmPlan(chargeId);
        } else {
            setState(RequestStateEnum.ERROR);
        }
    }, [confirmPlan]);

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Layout>
                <div
                    style={{
                        marginTop: '-10vh',
                    }}>
                    <Card>
                        <div
                            style={{
                                minHeight: '20vh',
                                minWidth: '50vw',
                                maxWidth: '70vw',
                                padding: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}>
                            {state === RequestStateEnum.INIT ||
                            state === RequestStateEnum.LOADING ? (
                                <>
                                    <DisplayText
                                        size='medium'
                                        style={{
                                            marginBottom: '20px',
                                        }}>
                                        Confirming your purchase...
                                    </DisplayText>
                                    <div
                                        style={{
                                            marginTop: '30px',
                                        }}>
                                        <Spinner size='large' />
                                    </div>
                                </>
                            ) : state === RequestStateEnum.ERROR ? (
                                <>
                                    <DisplayText
                                        size='small'
                                        style={{
                                            marginBottom: '20px',
                                        }}>
                                        Unfortunately, there was a problem with
                                        confirming your plan activation. If
                                        Shopify is charging you please contact
                                        us at support@visionbrain.ai, Thanks!
                                    </DisplayText>
                                    <div
                                        style={{
                                            marginTop: '30px',
                                        }}>
                                        <Button
                                            primary
                                            onClick={() => {
                                                navigate('/');
                                            }}>
                                            GO TO MAIN PAGE
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <DisplayText
                                        size='small'
                                        style={{
                                            marginBottom: '20px',
                                        }}>
                                        {newShop
                                            ? "Thank you! You'll now move on to sync process..."
                                            : "Thank you! You'll now be redirected to the main page."}
                                    </DisplayText>
                                </>
                            )}
                        </div>
                    </Card>
                </div>
            </Layout>
        </div>
    );
}
