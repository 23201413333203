import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    List,
    TextContainer,
    TextStyle,
    SkeletonBodyText,
    EmptyState,
    Toast,
    Badge,
    Icon,
} from '@shopify/polaris';
import SyncProgressBar from './syncProgressBar';
import { mainApi } from '../mainApi';
import {
    MinusMinor,
    PlusMinor,
    TickMinor,
    DeleteMinor,
} from '@shopify/polaris-icons';
export default function SyncStatusCard({ onSuccess }) {
    const [toast, setToast] = useState({
        active: false,
        message: '',
        error: false,
    });

    const [syncStatus, setSyncStatus] = useState({
        status: '',
        lastSyncDate: null,
    });

    const [requestStatus, setRequestStatus] = useState({
        loading: false,
        error: false,
    });

    const getSyncStatus = async () => {
        try {
            setRequestStatus({ loading: true, error: false });
            const { data } = await mainApi.get('/sync/');
            setRequestStatus({ loading: false, error: false });
            setSyncStatus({
                status: data.state,
                lastSyncDate: data.date_done,
                totalCount: data.result && data.result.total,
                unchangedCount: data.result && data.result.unchanged,
                deletedCount: data.result && data.result.deactivated,
                addedCount: data.result && data.result.created,
                updatedCount: data.result && data.result.updated,
            });
        } catch (error) {
            setRequestStatus({ error: true, loading: false });
        }
    };

    const onSyncFinished = useCallback(
        (data) => {
            if (data) {
                setSyncStatus({
                    status: data.state,
                    lastSyncDate: data.date_done,
                    totalCount: data.result && data.result.total,
                    unchangedCount: data.result && data.result.unchanged,
                    deletedCount: data.result && data.result.deactivated,
                    addedCount: data.result && data.result.created,
                    updatedCount: data.result && data.result.updated,
                });
                if (data.state === 'SUCCESS') {
                    onSuccess && onSuccess();
                }
            } else {
                // getSyncStatus();
            }
        },
        [onSuccess]
    );

    const showToast = (message, error = false) => {
        setToast({ active: true, message, error });
    };

    const resync = async () => {
        try {
            await mainApi.post('/sync/', { commit: true });
            showToast('Sync Started');
            getSyncStatus();
        } catch (error) {
            showToast('Error', true);
        }
    };

    const tableHeadings = (data) => {
        return data.map((item) => {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}>
                    <span>
                        <Icon source={item.icon} color='base' />
                    </span>
                    <p style={{ marginLeft: '5px' }}>{item.text}</p>
                </div>
            );
        });
    };

    useEffect(() => {
        getSyncStatus();
    }, []);

    if (requestStatus.error) {
        return (
            <Card title='Sync Status'>
                <Card.Section>
                    <EmptyState
                        heading='Error'
                        action={{
                            content: 'Try again',
                            onAction: getSyncStatus,
                        }}
                        image='images/warning.png'
                        imageContained>
                        <p>
                            There was a problem with connecting to the server.
                            Please try again.
                        </p>
                    </EmptyState>
                </Card.Section>
            </Card>
        );
    } else if (requestStatus.loading) {
        return (
            <Card title='Sync Status'>
                <Card.Section>
                    <SkeletonBodyText />
                </Card.Section>
                <Card.Section>
                    <Card.Subsection>
                        <SkeletonBodyText />
                    </Card.Subsection>
                    <Card.Subsection>
                        <SkeletonBodyText />
                    </Card.Subsection>
                </Card.Section>
            </Card>
        );
    } else if (syncStatus.status === 'SUCCESS') {
        return (
            <>
                <Card
                    primaryFooterAction={{
                        content: 'Re-sync',
                        onAction: resync,
                    }}
                    title='Sync Status'>
                    <Card.Section subdued>
                        <List>
                            <List.Item>
                                <TextContainer>
                                    <TextStyle variation='strong'>
                                        Last manual sync:{' '}
                                    </TextStyle>
                                    {new Date(
                                        syncStatus.lastSyncDate
                                    ).toUTCString()}
                                </TextContainer>
                            </List.Item>

                            <List.Item>
                                <TextContainer>
                                    <TextStyle variation='strong'>
                                        All products are synced.
                                    </TextStyle>
                                </TextContainer>
                            </List.Item>
                        </List>
                    </Card.Section>

                    <Card.Section title='Products Breakdown'>
                        <Card.Subsection>
                            <TextContainer>
                                The total count of products synced is:{' '}
                                <Badge status='success'>
                                    {syncStatus.totalCount}
                                </Badge>
                            </TextContainer>
                        </Card.Subsection>
                        <Card.Subsection>
                            <TextContainer>
                                <TextStyle variation='strong'>
                                    Last sync status:
                                </TextStyle>
                            </TextContainer>
                            <div
                                style={{
                                    margin: '20px 0',
                                    overflow: 'hidden',
                                }}>
                                <table
                                    style={{
                                        width: '100%',
                                        position: 'relative',
                                        fontSize: '1.2rem',
                                        textAlign: 'center',
                                    }}>
                                    <tbody>
                                        <tr>
                                            {tableHeadings([
                                                {
                                                    icon: MinusMinor,
                                                    text: 'Unchanged',
                                                },
                                                {
                                                    icon: PlusMinor,
                                                    text: 'Added',
                                                },
                                                {
                                                    icon: TickMinor,
                                                    text: 'Updated',
                                                },
                                                {
                                                    icon: DeleteMinor,
                                                    text: 'Deleted',
                                                },
                                            ]).map((item, i) => {
                                                return <td key={i}>{item}</td>;
                                            })}
                                        </tr>
                                        <tr>
                                            <td>
                                                <Badge>
                                                    {syncStatus.unchangedCount}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Badge>
                                                    {syncStatus.addedCount}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Badge status='success'>
                                                    {syncStatus.updatedCount}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Badge status='critical'>
                                                    {syncStatus.deletedCount}
                                                </Badge>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Card.Subsection>
                    </Card.Section>
                </Card>
                {toast.active ? (
                    <Toast
                        content={toast.message}
                        error={toast.error}
                        duration={4500}
                        onDismiss={() => {
                            setToast({ active: false, error: false });
                        }}
                    />
                ) : null}
            </>
        );
    } else if (
        syncStatus.status === 'FAILURE' ||
        syncStatus.status === 'REVOKED'
    ) {
        return (
            <Card title='Sync Status'>
                <Card.Section>
                    <EmptyState
                        heading='Sync Error'
                        action={{
                            content: 'Try again',
                            onAction: resync,
                        }}
                        image='images/warning.png'
                        imageContained>
                        <p>
                            There was a problem with syncing the products.
                            Please try again.
                        </p>
                    </EmptyState>
                </Card.Section>
            </Card>
        );
    } else if (syncStatus.status === 'NOT SYNCED') {
        return (
            <Card title='Sync Status'>
                <Card.Section>
                    <EmptyState
                        heading='We need to sync your products first'
                        action={{
                            content: 'Sync now',
                            onAction: resync,
                        }}
                        image='images/start.png'
                        imageContained>
                        <p>
                            Your store products have never been synced before.
                            So let's get started and sync them!
                        </p>
                    </EmptyState>
                </Card.Section>
            </Card>
        );
    } else {
        return (
            <Card title='Sync Status'>
                <Card.Section>
                    <SyncProgressBar
                        color='primary'
                        size='small'
                        onFinished={onSyncFinished}
                    />
                </Card.Section>
            </Card>
        );
    }
}
