import React from 'react';
import { EmptyState } from '@shopify/polaris';
import { useNavigate } from 'react-router';

export default function OldShopWelcomePage() {
    const navigate = useNavigate();
    return (
        <div
            style={{
                margin: 'auto',
                maxWidth: '700px',
                width: '90%',
                marginTop: '20px',
                overflow: 'hidden',
                padding: '20px',
            }}>
            <EmptyState
                fullWidth={true}
                heading='Empower your shop with Smart Visual Search!'
                action={{
                    content: 'Start now',
                    onAction: () => {
                        navigate('/plans');
                    },
                }}
                image='images/hello.svg'
                largeImage='images/hello.svg'>
                <div
                    style={{
                        width: '100%',
                        height: '1px',
                        background: '#ccc',
                    }}></div>
                <ul style={{ textAlign: 'left' }}>
                    <li style={{ marginTop: '25px' }}>
                        Thank you for standing besides us to create such a
                        wonderful journey together to build the Smart Visual
                        Search Shopify app. After all this time, we have
                        gathered feedback from several shops to create a better
                        experience for shops and their customers.
                        <br></br>
                        <li style={{ marginTop: '15px' }}>
                            The new version of the app is ready for use with a
                            better integration with Shopify’s platform, full
                            customization of color, texts, etc. and a completely
                            new UI design.
                        </li>
                        <li style={{ marginTop: '15px' }}>
                            For maintaining the Smart Visual Search app, we are
                            going to add a purchase plan to the app. From
                        </li>
                        <strong style={{ color: '#008060' }}>
                            {' '}
                            January 1st 2022{' '}
                        </strong>
                        onwards, the app is accessible by activating a
                        subscription plan. Until that date, the app will
                        continue to work, but without any customization options
                        available.
                    </li>
                    <li style={{ marginTop: '15px' }}>
                        Because of your help to enhance the app, you will
                        receive a
                        <b style={{ color: '#008060' }}> 30 day free trial </b>
                        on your first plan activation which we hope you’ll
                        enjoy.
                    </li>
                </ul>
                <p style={{ marginTop: '20px' }}>
                    Please choose the plan you want to activate now and
                    <strong> enjoy your 30 day trial!</strong>
                </p>
            </EmptyState>
        </div>
    );
}
