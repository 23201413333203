import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProgressBar, EmptyState } from '@shopify/polaris';
import { mainApi } from '../mainApi';

export default function SyncProgressBar({ onFinished, ...propsRest }) {
    const [progress, setProgress] = useState(0);
    const [texts, setTexts] = useState({ heading: '', body: '' });

    const timerId = useRef(0);

    const syncProgressBar = useCallback(async () => {
        try {
            const { data } = await mainApi.get('/sync/');
            switch (data.state) {
                case 'SYNC':
                    let { current, total } = data.result;
                    setProgress((current * 100) / total);
                    setTexts({
                        heading: 'Sync In Progress',
                        body: `Products are being synced... (${current} out of ${total})`,
                    });
                    break;
                case 'STARTED':
                    setProgress(0);
                    setTexts({
                        heading: 'Sync Started',
                        body: 'Sync process has started.',
                    });
                    break;
                case 'FETCH':
                    setProgress(0);
                    setTexts({
                        heading: 'Fetching Data',
                        body: 'Products data is being fetched...',
                    });
                    break;
                default:
                    clearInterval(timerId.current);
                    onFinished && onFinished(data);
            }
        } catch (error) {
            clearInterval(timerId.current);
            onFinished && onFinished();
        }
    }, [onFinished]);

    useEffect(() => {
        (async () => {
            await syncProgressBar();
            if (!timerId.current) {
                timerId.current = setInterval(syncProgressBar, 500);
            }
            return () => {
                clearInterval(timerId.current);
            };
        })();
    }, [syncProgressBar]);

    return (
        <>
            <EmptyState
                heading={texts.heading}
                image={'images/sync.png'}
                imageContained>
                <p style={{ width: '100%' }}>{texts.body}</p>
            </EmptyState>
            <ProgressBar progress={progress} {...propsRest} />
        </>
    );
}
