import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    EmptyState,
    Spinner,
    DatePicker,
    TextField,
    Modal,
    Icon,
} from '@shopify/polaris';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { mainApi } from '../mainApi';
import { RequestStateEnum } from '../common';
import { CalendarMinor } from '@shopify/polaris-icons';

export default function ChartCard() {
    const [chart, setChart] = useState({
        status: RequestStateEnum.INIT,
        data: [],
        labels: [],
    });
    // handle date picker data change
    const [{ month, year }, setDate] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    });
    const [selectedDates, setSelectedDates] = useState({
        // start -> last 7 day from today
        start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        end: new Date(),
    });
    const handleMonthChange = useCallback(
        (month, year) => setDate({ month, year }),
        []
    );
    // format data to yy/mm/dd
    const dateFormat = (date) => {
        const d = new Date(date);
        const month = '' + (d.getMonth() + 1);
        const day = '' + d.getDate();
        const year = d.getFullYear();
        return [year, month, day].join('-');
    };

    const [showModal, setShowModal] = useState(false);
    const toggleActive = useCallback(
        () => setShowModal((state) => !state),
        [setShowModal]
    );

    const fetchData = useCallback(async () => {
        try {
            setChart({
                status: RequestStateEnum.LOADING,
            });
            const { data: data_ } = await mainApi.get(
                `/stats/?start_date=${dateFormat(
                    selectedDates.start
                )}&end_date=${dateFormat(selectedDates.end)}`
            );
            const {
                start_date: start,
                end_date: end,
                query_counts: data,
            } = data_;
            const startDate = new Date(start);
            const endDate = new Date(end);

            const labels = [];

            for (
                let date = startDate;
                date <= endDate;
                date.setDate(date.getDate() + 1)
            ) {
                labels.push(dateFormat(date));
            }
            setChart({
                labels,
                data,
                status: RequestStateEnum.SUCCESS,
            });
        } catch {
            setChart({
                status: RequestStateEnum.ERROR,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (chart.status === RequestStateEnum.ERROR) {
        return (
            <Card title='Statistics'>
                <Card.Section>
                    <EmptyState
                        heading='Error'
                        action={{
                            content: 'Try again',
                            onAction: fetchData,
                        }}
                        image='/warning.png'
                        imageContained>
                        <p>
                            There was a problem with connecting to the server.
                            Please try again.
                        </p>
                    </EmptyState>
                </Card.Section>
            </Card>
        );
    } else if (chart.status === RequestStateEnum.LOADING) {
        return (
            <Card title='Statistics'>
                <div
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        padding: '100px 0px',
                    }}>
                    <p style={{ margin: '20px 0px' }}>Fetching data...</p>
                    <Spinner
                        accessibilityLabel='Fetching data'
                        size='large'
                        color='teal'
                    />
                </div>
            </Card>
        );
    } else {
        return (
            <>
                <Card title='Statistics'>
                    <div
                        onClick={() => {
                            toggleActive();
                        }}>
                        <div
                            style={{
                                marginRight: '20px',
                                marginLeft: 'auto',
                                width: '220px',
                                cursor: 'pointer',
                            }}>
                            <p
                                style={{
                                    marginBottom: '10px',
                                    textAlign: 'right',
                                }}>
                                Date Filter
                            </p>
                            <TextField
                                readOnly
                                value={
                                    dateFormat(selectedDates.start) +
                                    '  |  ' +
                                    dateFormat(selectedDates.end)
                                }
                                type='text'
                                suffix={
                                    <Icon source={CalendarMinor} color='base' />
                                }
                            />
                        </div>
                    </div>

                    <Card.Section>
                        <Bar
                            data={{
                                labels: chart.labels,
                                datasets: [
                                    {
                                        label: 'Search Count',
                                        backgroundColor: 'rgba(0, 128, 96,0.2)',
                                        borderColor: 'rgba(0, 128, 96,1)',
                                        borderWidth: 0,
                                        hoverBackgroundColor:
                                            'rgba(0, 128, 96,0.4)',
                                        hoverBorderColor: 'rgba(0, 128, 96,1)',
                                        data: chart.data,
                                    },
                                ],
                            }}
                            width={100}
                            height={500}
                            options={{
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                            },
                                        },
                                    ],
                                },
                                maintainAspectRatio: false,
                            }}
                        />
                    </Card.Section>
                </Card>
                <Modal
                    small
                    open={showModal}
                    onClose={toggleActive}
                    title='Date Filter'
                    primaryAction={{
                        content: 'Close',
                        onAction: toggleActive,
                    }}
                    limitHeight={false}>
                    <div
                        style={{
                            padding: '10px',
                        }}>
                        <DatePicker
                            month={month}
                            year={year}
                            onChange={setSelectedDates}
                            onMonthChange={handleMonthChange}
                            selected={selectedDates}
                            disableDatesAfter={new Date()}
                            allowRange
                        />
                    </div>
                </Modal>
            </>
        );
    }
}
